// Grid
$team-spacing: 32px;
$team-spacing-s: 40px;
$team-vertical-spacing: 40px;
$team-vertical-spacing-s: 40px;

.c-team__row {
  @include make-row-spacing($team-spacing, $team-vertical-spacing);

  @include mappy-bp(s) {
    @include make-row-spacing($team-spacing-s, $team-vertical-spacing-s);
  }
}

.c-team__col {
  @include make-col-spacing($team-spacing, $team-vertical-spacing);

  @include mappy-bp(s) {
    @include make-col-spacing($team-spacing-s, $team-vertical-spacing-s);
  }
}

