//
// Search widget
// --------------------------------------------------

.c-search-widget {
  display: none;

  @include mappy-bp(lg) {
    position: absolute;
    right: 0;
    top: 100%;
    min-width: rem(250px);
    background-color: #fff;
    box-shadow: $menu-submenu-shadow;
    border-top: rem(4px) solid $color-primary;
    padding: rem(16px) rem($menu-item-submenu-padding-lg);

    font-weight: 500;
    @include typi('menu-level2');

    &.is-open {
      display: block;
    }
  }
}
