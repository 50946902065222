//
// Utilities
// --------------------------------------------------

// Screen reader friendly hiding utility
.u-invisible {
  margin: -3px;
  position: absolute;
  text-indent: 100%;
  overflow: hidden;
  height: 1px;
  width: 1px;
  display: block;
}
