//
// Cards
// --------------------------------------------------

// Cards grid
.c-cards {
  & + & {
    margin-top: rem(64px);

    @include mappy-bp(lg) {
      margin-top: rem(96px);
    }
  }
}

.c-cards__cta {
  margin-top: rem(40px);
}
