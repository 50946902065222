//
// Footer
// --------------------------------------------------

.c-footer {
  @include overlay-blur;
  position: relative;
  z-index: index($z-index, footer);
  background-color: $color-gray-f;
  padding-top: rem(24px);
  padding-bottom: rem(24px);
  @include typi('footer');

  @include mappy-bp(sm) {
    padding-top: rem(48px);
  }

  address {
    a {
      &,
      &:hover,
      &:focus {
        color: inherit;
      }
    }
  }
}


// Grid
$footer-spacing: 32px;
$footer-vertical-spacing: 24px;

.c-footer__row {
  @include make-row-spacing($footer-spacing, $footer-vertical-spacing);
}

.c-footer__col {
  @include make-col-spacing($footer-spacing, $footer-vertical-spacing);
}


.c-footer__alpha {
  position: relative;

  // fake element for ignoring row top margin
  &::before {
    content: '';
    display: block;
    height: 1px;
    margin-top: -1px;
  }
}

.c-footer__address {
  p,
  address {
    font-size: inherit;
    line-height: inherit;
  }
}

.c-footer__social {
  display: flex;
  margin-top: rem($footer-vertical-spacing);

  @include mappy-bp(sm) {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  a {
    display: block;
    height: rem(32px);
    width: rem(32px);
    color: $color-link;
    transition: color $transition-time-default;

    &:hover,
    &:focus {
      color: $color-link-hover;
      text-decoration: underline;
    }

    &:not(:first-of-type) {
      margin-left: rem(16px);
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.c-footer__beta {
  border-top: rem(4px) solid $color-tertiary-light;
  padding-top: rem(24px);
  margin-top: rem(24px);

  @include mappy-bp(sm) {
    margin-top: rem(40px);

    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.c-footer__nav {
  flex-shrink: 0;
  margin-top: rem(16px);

  @include mappy-bp(sm) {
    margin-top: 0;
    margin-left: rem(32px);
  }

  ul {
    margin: rem(-4px) rem(-16px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  li {
    margin: rem(4px) rem(16px);

    a {
      color: $color-link;
      transition: color $transition-time-default;
      font-weight: $font-bold;

      &:hover,
      &:focus {
        color: $color-link-hover;
      }
    }
  }
}

.c-footer__imprint {
  @include typi('paragraph-sm');
}
