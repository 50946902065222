//
// Footer
// --------------------------------------------------

.c-footer-cta {
  position: relative;
  background-color: #000;
  color: #fff;
  padding-top: rem(40px);
  padding-bottom: rem(40px);

  @include mappy-bp(sm) {
    padding-top: rem(72px);
    padding-bottom: rem(72px);
  }

  @include mappy-bp(lg) {
    padding-top: rem(112px);
    padding-bottom: rem(112px);
  }

  .c-headline {
    padding-bottom: rem(24px);
    color: $color-primary;
  }

  .c-button-group {
    margin-top: rem(12px);
  }

  .o-container {
    position: relative;
    z-index: 10; // > bg visual
  }
}

// BG visual
$footer-cta-visual-ratio: 646 / 1034;

.c-footer-cta__bg-visual {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.c-footer-cta__bg-visual-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @include mappy-bp(md) {
    max-width: 75%;
  }

  @include mappy-bp(lg) {
    max-width: 69%;
  }

  &::after {
    content: '';
    display: block;
    padding-bottom: 100% * $footer-cta-visual-ratio;
  }

  svg {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;


    animation: footerCTAVisual 9s infinite alternate ease-in-out;
  }
}

@keyframes footerCTAVisual {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
