//
// Blockquote
// --------------------------------------------------

$blockquote-icon-size: 32px;
$blockquote-icon-size-sm: 40px;
$blockquote-icon-ratio: 24 / 24;

.c-blockquote:not(.c-blockquote--content-only) {
  color: $color-text;
  display: flex;
  position: relative;

  @include mappy-bp(sm) {
    padding: 0 rem(36px) rem(36px);

    &::before {
      content: '';
      background-color: $color-gray-d;
      position: absolute;
      left: 0;
      top: rem(36px);
      bottom: 0;
      right: 0;
    }

    @include mappy-bp(md) {
      padding: 0 0 rem(40px);

      &::before {
        left: rem(-40px);
        right: rem(40px);
        top: rem(40px);
      }
    }
  }
}

// may be omitted along with `content-only` modifier being set
.c-blockquote__content {
  position: relative;
  z-index: 10; // > background
  background-color: $color-gray-f;
  flex-grow: 1;
  padding: rem(24px) rem(16px);

  @include mappy-bp(sm) {
    padding: rem(48px) rem(24px);
  }

  @include mappy-bp(md) {
    padding: rem(56px) rem(112px);
  }

  .c-blockquote--with-image & {
    padding: rem(24px);

    @include mappy-bp(sm) {
      padding: rem(48px) rem(40px);
    }

    @include mappy-bp(md) {
      padding: rem(72px);
    }
  }
}

.c-blockquote__icon {
  color: $color-primary;

  width: rem($blockquote-icon-size);
  height: rem($blockquote-icon-size * $blockquote-icon-ratio);
  margin-bottom: rem(24px);

  @include mappy-bp(sm) {
    width: rem($blockquote-icon-size-sm);
    height: rem($blockquote-icon-size-sm * $blockquote-icon-ratio);
  }

  @include mappy-bp(md) {
    margin-bottom: rem(40px);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.c-blockquote__cite {
  display: block;

  .c-blockquote__quote + & {
    margin-top: rem(24px);

    @include mappy-bp(md) {
      margin-top: rem(40px);
    }
  }
}

.c-blockquote__company {
  text-transform: uppercase;
  font-weight: 500;
}
