//
// Snippet teaser
// --------------------------------------------------

.c-teaser-snippet {
  @include typi('paragraph');
  color: $color-link;
  transition: color $transition-time-default;

  display: inline-flex;
  align-items: baseline;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }
}

.c-teaser-snippet__prefix {
  margin-right: rem(16px);
  flex-shrink: 0;
  width: rem($list-bullet-space);
  font-weight: $font-bold;

  @include mappy-bp(sm) {
    width: rem($list-bullet-space-sm);
  }

  @include mappy-bp(lg) {
    width: rem($list-bullet-space-lg);
  }
}

.c-teaser-snippet__text {
  flex-grow: 1;
  font-weight: 500;
}
