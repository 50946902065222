//
// Scaffolding
// --------------------------------------------------

// sets base font-sizing on html element
@include typi-init;

html {
  box-sizing: border-box;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: $color-text;
  background-color: #fff;
  @include font-sans;
  @include font-smoothing;

  // Dark mode is not supported
  /* stylelint-disable media-feature-name-no-unknown */
  @media (prefers-color-scheme: dark) {
    color: $color-text;
    background-color: #fff;
  }
}

::selection {
  background: $color-primary;
  color: #fff;
}

.u-cookieconsent-initialiser {
  display: none;
}
