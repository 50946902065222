//
// Alert
// --------------------------------------------------

$alert-icon-size: 32px;

.c-alert {
  background-color: $color-primary-lighter;
  color: $color-text-dark;
  padding: rem(16px) rem(24px);

  display: flex;
}

.c-alert--success {
  background-color: $color-success;
  color: #fff;
}

.c-alert--danger {
  background-color: $color-error;
  color: #fff;
}

.c-alert__icon {
  flex-shrink: 0;
  margin-right: rem(16px);

  svg {
    height: rem($alert-icon-size);
    width: rem($alert-icon-size);
  }
}

.c-alert__text {
  font-weight: $font-bold;
  flex-grow: 1;
  align-self: center;
}

.c-alert__closer {
  flex-shrink: 0;
  margin-left: rem(16px);
  height: rem($alert-icon-size);
  width: rem($alert-icon-size);

  &,
  &:hover,
  &:focus {
    color: inherit;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: rem(18px);
    width: rem(18px);
  }
}
