//
// Line views
// --------------------------------------------------

// List
.c-lineviews__item {
  & + & {
    margin-top: rem(24px);
  }
}

// Single view
.c-line {
  display: flex;
  position: relative;
  color: $color-link;
  transition: all $transition-time-default;
  background-color: $color-gray-f;
  padding: rem(16px);

  @include mappy-bp(md) {
    padding: rem(24px);
  }

  &:hover,
  &:focus {
    color: $color-link-hover;
    background-color: $color-gray-d;
  }
}

.c-line__icon {
  align-self: baseline;
  width: rem(18px);
  height: rem(18px);
  margin-right: rem(8px);
  flex-shrink: 0;
  color: $color-link-hover;

  @include mappy-bp(md) {
    align-self: center;
    width: rem(24px);
    height: rem(24px);
    margin-right: rem(16px);
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.c-line__content {
  flex-grow: 1;

  @include mappy-bp(md) {
    display: flex;
    align-items: center;
  }
}

.c-line__meta {
  color: $color-text;
  margin-top: rem(8px);

  @include mappy-bp(md) {
    margin-top: 0;
    margin-left: auto;
    text-align: right;
  }
}

.c-richtext {
  .c-line__meta-key {
    text-transform: uppercase;
  }

  .c-line__meta-value {
    font-weight: 700;
    @include typi('headline-meta');
  }
}
