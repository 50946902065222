//
// Suppliers
// --------------------------------------------------

// Grid
$trading-partners-spacing: 32px;
$trading-partners-spacing-s: 40px;
$trading-partners-vertical-spacing: 24px;
$trading-partners-vertical-spacing-s: 40px;

.c-trading-partners__row {
  @include make-row-spacing($trading-partners-spacing, $trading-partners-vertical-spacing);

  @include mappy-bp(lg) {
    @include make-row-spacing($trading-partners-spacing-s, $trading-partners-vertical-spacing-s);
  }
}

.c-trading-partners__col {
  @include make-col-spacing($trading-partners-spacing, $trading-partners-vertical-spacing);

  @include mappy-bp(lg) {
    @include make-col-spacing($trading-partners-spacing-s, $trading-partners-vertical-spacing-s);
  }
}

