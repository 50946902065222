//
// Colors
// --------------------------------------------------

// Shades of gray
$color-gray-f: #f2f2f2;
$color-gray-e: #ebebeb;
$color-gray-d: #d9dde1;
$color-gray-b: #b2b1a7;
$color-gray-5: #57564a;
$color-gray-3: #3c3b32;

// CI colors
$color-primary: #5580c0;
$color-primary-dark: #3d63ab;
$color-primary-darker: #2d4c87;
$color-primary-lighter: #e0e9fa;
$color-secondary: $color-gray-3;
$color-secondary-light: $color-gray-5;
$color-secondary-lighter: $color-gray-b;
$color-tertiary: #778697;
$color-tertiary-light: $color-gray-d;

$color-green: #1cb128;
$color-orange: #e49b00;
$color-red: #c7475e;

// Mapping
$color-text: $color-secondary-light;
$color-text-dark: $color-secondary;
$color-text-inverted: #fff;

$color-link: $color-primary;
$color-link-hover: #000;
$color-link-inverted: $color-primary;
$color-link-inverted-hover: $color-primary;

$color-error: $color-red;
$color-success: $color-green;
$color-warning: $color-orange;

