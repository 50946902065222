//
// Topbar
// --------------------------------------------------

:root {
  --topbar-height: #{$estimated-topbar-height}; // should be adjusted by JS
}

@keyframes topbarNodeReady {
  from { clip: rect(1px, auto, auto, auto); }
  to { clip: rect(0, auto, auto, auto); }
}

.c-topbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: index($z-index, topbar);
  background-color: #fff;
  will-change: transform; // opens up a new stacking context, super important for megamenu positioning

  // https://keithclark.co.uk/articles/working-with-elements-before-the-dom-is-ready/
  animation-name: topbarNodeReady;
  animation-duration: .001s;

  &::after {
    content: '';
    z-index: -1; // < topbar content
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;

    transition: opacity $transition-time-medium;
    box-shadow: 0 0 rem(30px) rem(2px) rgba(0, 0, 0, .2);
  }
}

// Headroom modifiers
.c-topbar--headroom-initialized {
  transition: all $transition-time-medium;
}

.c-topbar--not-top {
  &::after {
    opacity: 1;
  }
}

.is-overlay-menu-open .c-topbar::after {
  @include mappy-bp(max lg) {
    opacity: 0;
  }
}

.c-topbar--unpinned {
  transform: translateY(-100%);

  &::after {
    opacity: 0;
  }
}


.c-topbar__slideout-banner {
  border-bottom: 1px solid rgba($color-primary-dark, .2);

  &.is-hidden {
    display: none;
  }
}

.c-topbar__main {
  @include overlay-blur;

  height: rem($topbar-height);

  @include mappy-bp(sm) {
    height: rem($topbar-height-sm);
  }

  @include mappy-bp(lg) {
    height: rem($topbar-height-lg);
  }
}

.c-topbar__container {
  height: 100%;
}

.c-topbar__inner {
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-topbar__left {
  flex-shrink: 0;
  display: flex;
}

// Logo
.c-topbar__logo {
  display: block;
  font-size: 1rem; // all sizing is based on this

  @include mappy-bp(sm) {
    font-size: 1.125rem;
  }

  @include mappy-bp(lg) {
    font-size: 1.5em;
    transform: translateY(rem(-4px));
  }

  &,
  &:hover,
  &:focus {
    color: $color-primary;
  }

  svg {
    height: 2em;
    width: 2em * $logo-ratio;
  }
}

.c-topbar__logo--comer {
  font-size: 1.6rem;

  @include mappy-bp(sm) {
    font-size: 2.2rem;
  }

  @include mappy-bp(lg) {
    font-size: 2.2em;
    transform: none;
  }

  @include mappy-bp(xl) {
    font-size: 2.365em;
  }

  img {
    display: block;
    height: 2em;
    width: 2em * $logo-ratio-comer;
  }
}

.c-topbar__logo-claim-wrapper {
  display: block;
  margin-left: 2.45em; // roughly align with "e"urodrives
  margin-top: -.29em;

  @include mappy-bp(max sm) {
    display: none;
  }
}

.c-topbar__logo-claim {
  display: block;
  line-height: 1;
  white-space: nowrap;
  font-size: .71em;
}


.c-topbar__right {
  height: 100%;
  margin-left: rem(24px);
  flex-shrink: 0;

  display: flex;
  align-items: center;

  @include mappy-bp(lg) {
    padding-top: rem(16px);
    flex-direction: column;
    align-items: flex-end;
  }
}

.c-topbar__service {
  position: relative;
  z-index: 10; // > menu wrapper
  margin-right: rem(-$lang-switch-padding);
  margin-top: rem(-$lang-switch-padding);
  display: flex;

  @include mappy-bp(max lg) {
    display: none;
  }
}

.c-topbar__service-item {
  display: flex;
  align-items: center;
  color: $color-link;
  @include typi('service');
  font-weight: 500;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }

  & + & {
    margin-left: rem(24px);
  }

  svg {
    color: $color-link-hover;
    width: rem(18px);
    height: rem(18px);
    margin-right: rem(8px);
  }
}

.c-topbar__lang {
  .c-topbar__service-item + & {
    margin-left: rem(24px - $lang-switch-padding);
  }
}

.c-topbar__menu-wrapper {
  position: relative;
  z-index: 5; // < service nav

  margin-top: auto;
  display: flex;

  @include mappy-bp(max lg) {
    display: none;
  }
}

.c-topbar__search {
  margin-top: rem(4px); // kinda magic
  margin-left: rem(10px);
  height: rem(24px);
  width: rem(24px);
  color: $color-link;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }

  svg {
    height: 100%;
    width: 100%;

    .is-overlay-search-widget-open &:first-of-type {
      display: none;
    }

    &:last-of-type {
      display: none;
      transform: scale(.8);

      .is-overlay-search-widget-open & {
        display: block;
      }
    }
  }
}

.c-topbar__menu-toggler {
  flex-shrink: 0;
  min-width: 0;
  display: block;
  color: $color-link;

  @include mappy-bp(lg) {
    display: none;
  }
}

.c-topbar__burger {
  display: block;
  flex-shrink: 0;
  position: relative;
  width: rem($topbar-btn-size);
  height: rem($topbar-btn-size);

  @include mappy-bp(sm) {
    width: rem($topbar-btn-size-sm);
    height: rem($topbar-btn-size-sm);
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
