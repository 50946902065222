//
// Product detail
// --------------------------------------------------

// Intro
.c-product__intro {
  margin-top: rem(-20px); // visual top spacing fix
  margin-bottom: rem(40px);

  @include mappy-bp(md) {
    margin-bottom: rem(48px);
  }
}


// Grid
.c-product__grid {
  display: grid;
  gap: rem(40px);
  grid-template-areas:
    "main-alpha"
    "aside"
    "main-beta";

  @include mappy-bp(md) {
    row-gap: rem(48px);
    grid-template-columns: ((100fr / 12) * 7) ((100fr / 12) * 5); // col-7 / col-5
    grid-template-areas:
      "main-alpha aside"
      "main-beta  aside";
  }
}

.c-product__grid-main-alpha {
  grid-area: main-alpha;
}

.c-product__grid-main-beta {
  grid-area: main-beta;
}

.c-product__grid-aside {
  grid-area: aside;
}


// Image
.c-product__img-wrapper {
  overflow: hidden;

  picture {
    width: 100%;
  }
}

.c-product__img,
.c-product__svg {
  display: block;
  width: 100%;
  height: auto;
  transition: all $transition-time-default;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}


// Feature table
.c-product__feature-table {
  margin-bottom: rem(40px);

  @include mappy-bp(md) {
    margin-bottom: rem(48px);
  }
}


// Chart
.c-product__chart {
  margin-bottom: rem(40px);

  @include mappy-bp(md) {
    margin-bottom: rem(48px);
  }
}

.c-product__chart-img-wrapper {
  overflow: hidden;

  picture {
    width: 100%;
  }
}

.c-product__chart-img,
.c-product__chart-svg {
  display: block;
  width: 100%;
  height: auto;
  transition: all $transition-time-default;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Contact block
.c-product__contact {
  background-color: $color-gray-f;
  padding: rem(24px);

  @include mappy-bp(sm) {
    padding: rem(40px);
  }

  h5 {
    line-height: 1.5;
  }
}

.c-product__contact-items {
  margin-bottom: rem(24px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.c-product__contact-item {
  display: flex;
  align-items: center;
  color: $color-link;
  transition: color $transition-time-default;
  font-size: rem(16px);
  font-weight: $font-bold;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }

  & + & {
    margin-top: rem(12px);
  }

  svg {
    color: $color-link-hover;
    width: rem(18px);
    height: rem(18px);
    margin-right: rem(8px);
  }
}


// Downloads / Image
.c-product__downloads,
.c-product__img-wrapper {
  margin-bottom: rem(40px);

  @include mappy-bp(md) {
    margin-bottom: rem(48px);
  }
}

.c-product__downloads .c-downloads,
.c-product__form .c-form,
.c-product__feature-table .c-table {
  margin-top: rem(8px);
}
