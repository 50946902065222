//
// Menu
// --------------------------------------------------

$menu-mobile-collapse-duration: $transition-time-default;
$menu-submenu-shadow: 0 rem(4px) rem(6px) 0 rgba(0, 0, 0, .2);
$menu-item-padding: 8px;
$menu-item-padding-sm: 12px;
$menu-item-padding-horizontal-lg: 16px;
$menu-item-submenu-padding-lg: 24px;
$menu-item-indicator-size: 12px;

// Shared between mobile & desktop

:root {
  --menu-position-x: 0; // should be adjusted by JS
}

// Icon
.c-menu-level2__icon {
  flex-shrink: 0;
  margin-right: rem(8px);
  width: rem(24px);
  height: rem(24px);
  transform: translateY(-.05em); // magic number for alignment
  color: $color-primary;

  svg {
    width: 100%;
    height: 100%;
  }
}

// Indicator
.c-menu-level1__indicator,
.c-menu-level2__indicator,
.c-menu-level3__indicator {
  flex-shrink: 0;
  margin-left: rem(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem($menu-item-indicator-size);
  height: rem($menu-item-indicator-size);
  color: $color-primary;

  svg {
    width: 100%;
    height: 100%;
    transition: all $menu-mobile-collapse-duration;
  }
}

.c-menu-level1__item.is-open .c-menu-level1__indicator,
.c-menu-level2__item.is-open .c-menu-level2__indicator,
.c-menu-level3__item.is-open .c-menu-level3__indicator {
  transform: rotate(180deg);
}

// Items headline
.c-menu-level2__items-headline,
.c-menu-level3__items-headline {
  @include typi('menu-items-headline');
  font-weight: $font-bold;
  text-transform: uppercase;
  margin-top: rem($menu-item-padding);
  color: $color-gray-b;

  &:not(:first-of-type) {
    margin-top: rem($menu-item-padding-sm);
  }
}


// Mobile menu only
@include mappy-bp(max lg) {
  // Level
  .c-menu-level2 {
    display: none;

    border-top: rem(1px) solid $color-gray-d;
    padding-top: rem($menu-item-padding);
    margin-top: rem($menu-item-padding / 2);
    margin-bottom: rem($menu-item-padding);

    .c-menu-level1__item.is-open & {
      display: block;
    }

    // reset possible megamenu container
    &.o-container {
      padding-left: 0;
      padding-right: 0;
      max-width: none;
    }
  }

  .c-menu-level3 {
    display: none;

    background-color: $color-gray-f;
    padding: rem($menu-item-padding) rem($menu-item-padding * 2);
    margin-bottom: rem($menu-item-padding);

    .c-menu-level2__item.is-open & {
      display: block;
    }
  }

  // Content box
  .c-menu-level1__content-box {
    background-color: #fff;
  }

  // Link
  .c-menu-level1__link,
  .c-menu-level2__link,
  .c-menu-level3__link {
    display: flex; // might contain indicator
    align-items: center;

    color: $color-link-hover;
    font-weight: $font-bold;
  }

  // Link active state
  .c-menu-level1__item.is-active .c-menu-level1__link,
  .c-menu-level2__item.is-active .c-menu-level2__link,
  .c-menu-level3__item.is-active .c-menu-level3__link {
    color: $color-link;
  }

  // Link open state
  .c-menu-level1__item.is-open .c-menu-level1__link,
  .c-menu-level2__item.is-open .c-menu-level2__link,
  .c-menu-level3__item.is-open .c-menu-level3__link {
    color: $color-link;
  }

  .c-menu-level1__link {
    padding-left: rem($menu-item-padding);
    padding-right: rem($menu-item-padding);
  }

  .c-menu-level2__link {
    padding-left: rem($menu-item-padding * 2);
    padding-right: rem($menu-item-padding);
  }

  .c-menu-level3__link {
    font-weight: 500;
  }

  // Title
  .c-menu-level1__title,
  .c-menu-level2__title,
  .c-menu-level3__title {
    margin-right: auto;
    padding-top: rem($menu-item-padding);
    padding-bottom: rem($menu-item-padding);
  }

  .c-menu-level1__title {
    @include typi('menu-level1');
    text-transform: uppercase;
  }

  .c-menu-level2__title {
    @include typi('menu-level2');
  }

  .c-menu-level3__title {
    @include typi('menu-level3');
  }

  // Items headline
  .c-menu-level2__items-headline {
    padding-left: rem($menu-item-padding * 2);
  }
}


// Desktop menu only
@include mappy-bp(lg) {
  .c-menu {
    height: 100%;
  }

  // Level
  .c-menu-level1 {
    height: 100%;
  }

  .c-menu-level2,
  .c-menu-level3 {
    display: none;
    position: absolute;
  }

  .c-menu-level2 {
    left: rem($menu-item-padding-horizontal-lg - $menu-item-submenu-padding-lg);
    top: 100%;

    .c-menu-level1__item:last-of-type & {
      right: rem($menu-item-padding-horizontal-lg - $menu-item-submenu-padding-lg);
      left: auto;
    }

    .c-menu-level1__item.is-open & {
      display: block;
    }
  }

  // Mega menu level 2
  .c-menu-level2--megamenu {
    position: fixed; // fixed on topbar stacking context
    left: 0;
    right: 0;
  }

  .c-menu-level3 {
    left: calc(100% + #{$menu-item-submenu-padding-lg / 2});
    top: 0;

    .c-menu-level1__item:last-of-type & {
      right: calc(100% + #{$menu-item-submenu-padding-lg / 2});
      left: auto;
    }

    .c-menu-level2__item.is-open & {
      display: block;
    }

    .c-menu-level2--megamenu & {
      display: block;
      position: relative;
      left: auto;
      right: auto;
      top: auto;
    }
  }

  // Content box
  .c-menu-level1__content-box {
    height: 100%;
  }

  .c-menu-level2__content-box,
  .c-menu-level2:not(.c-menu-level2--megamenu) .c-menu-level3__content-box {
    height: 100%;
    min-width: rem(250px);
    background-color: #fff;
    box-shadow: $menu-submenu-shadow;
    padding: rem(16px) rem($menu-item-submenu-padding-lg - 8px);

    border-top: rem(4px) solid $color-primary;
    position: relative;
  }

  .c-menu-level2__content-box {
    max-height: calc(100vh - (var(--topbar-height) * 1px));
    overflow: auto;

    // little triangle (not working cause `overflow: auto`; above)
    &::before {
      position: absolute;
      left: rem($menu-item-submenu-padding-lg);
      bottom: calc(100% + 4px - 1px); // 4px = border width, 1px = puffer
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 rem(7px) rem(7px) rem(7px);
      border-color: transparent transparent $color-primary transparent;
      content: '';

      .c-menu-level1__item:last-of-type & {
        right: rem($menu-item-submenu-padding-lg);
        left: auto;
      }
    }

    .c-menu-level2--megamenu &::before {
      left: calc(var(--menu-position-x) * 1px + #{$menu-item-padding-horizontal-lg});
    }
  }

  // List
  .c-menu-level1__list {
    height: 100%;
    display: flex;
  }

  .c-menu-level2__list,
  .c-menu-level3__list {
    padding-left: rem(8px);
    padding-right: rem(8px);
  }

  // megamenu level 2 = 2 columns
  .c-menu-level2--megamenu .c-menu-level2__list {
    padding-left: 0;
    padding-right: 0;

    column-count: 2;
    column-gap: rem(24px);
    column-fill: balance;
  }

  // megamenu level 3 = grid view
  .c-menu-level2--megamenu .c-menu-level3__list {
    display: grid;
    grid-template-columns: repeat(3, calc((100% - #{rem(16px)}) / 3));
    column-gap: rem(8px);
  }

  .c-menu-level2--megamenu .c-menu-level3__items-headline {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: rem(4px);
  }

  // Item
  .c-menu-level1__item,
  .c-menu-level2__item {
    position: relative; // for submenu
  }

  .c-menu-level2--megamenu .c-menu-level2__item:not(.c-menu-level2__item--overview) {
    margin-bottom: rem(16px);
    break-inside: avoid;
  }

  .c-menu-level2--megamenu .c-menu-level2__item--overview {
    text-align: center;
  }

  // Link wrapper
  .c-menu-level1__link-wrapper {
    position: relative;
    z-index: 10; // > submenu
  }

  .c-menu-level2--megamenu .c-menu-level2__link-wrapper {
    padding-left: rem(8px);
    padding-right: rem(8px);
  }

  // Link
  .c-menu-level1__link,
  .c-menu-level2__link,
  .c-menu-level3__link {
    display: flex; // might contain indicator
    align-items: center;

    color: $color-link-hover;
    font-weight: 500;
  }

  // Link active state
  .c-menu-level1__item.is-active .c-menu-level1__link,
  .c-menu-level2__item:not(.c-menu-level2__item--overview).is-active .c-menu-level2__link,
  .c-menu-level3__item.is-active .c-menu-level3__link {
    color: $color-link;
  }

  // Link hover/open state
  .c-menu-level1__link:hover,
  .c-menu-level1__link:focus,
  .c-menu-level1__item.is-open .c-menu-level1__link,
  .c-menu-level1__item.is-active .c-menu-level1__link:hover,
  .c-menu-level1__item.is-active .c-menu-level1__link:focus,
  .c-menu-level2__link:hover,
  .c-menu-level2__link:focus,
  .c-menu-level2:not(.c-menu-level2--megamenu) .c-menu-level2__item.is-open .c-menu-level2__link,
  .c-menu-level2__item.is-active .c-menu-level2__link:hover,
  .c-menu-level2__item.is-active .c-menu-level2__link:focus,
  .c-menu-level3__link:hover,
  .c-menu-level3__link:focus,
  .c-menu-level3__item.is-open .c-menu-level3__link,
  .c-menu-level3__item.is-active .c-menu-level3__link:hover,
  .c-menu-level3__item.is-active .c-menu-level3__link:focus {
    color: $color-link;
  }

  .c-menu-level1__link {
    padding-left: rem($menu-item-padding-horizontal-lg);
    padding-right: rem($menu-item-padding-horizontal-lg);
    padding-bottom: rem(24px);
    font-weight: $font-bold;
  }

  // Megamenu links / overview link
  .c-menu-level2--megamenu .c-menu-level3,
  .c-menu-level2__item--overview + .c-menu-level2__list {
    border-top: rem(1px) solid $color-gray-d;
    padding-top: rem($menu-item-padding);
    margin-top: rem($menu-item-padding);
  }

  .c-menu-level2--megamenu .c-menu-level2__link,
  .c-menu-level2__item--overview .c-menu-level2__link {
    font-weight: $font-bold;
    text-transform: uppercase;
  }

  .c-menu-level2--megamenu .c-menu-level2__item--overview .c-menu-level2__link {
    display: inline-flex;
    vertical-align: middle;
  }


  // Title
  .c-menu-level1__title,
  .c-menu-level2__title,
  .c-menu-level3__title {
    margin-right: auto;
    padding-top: rem(6px);
    padding-bottom: rem(6px);
  }

  .c-menu-level1__title {
    @include typi('menu-level1');
  }

  .c-menu-level2__title {
    @include typi('menu-level2');
  }

  .c-menu-level3__title {
    @include typi('menu-level3');
  }

  .c-menu-level2--megamenu .c-menu-level2__indicator,
  .c-menu-level2--megamenu .c-menu-level3__indicator {
    display: none;
  }
}


// Service menu
.c-menu-service {
  .c-menu + & {
    margin-top: rem(24px);
  }

  li {
    padding: rem($menu-item-padding);
  }

  a {
    display: block;
    @include typi('menu-level3');
    color: $color-link-hover;
    font-weight: 500;
  }

  li.is-active a {
    color: $color-link;
  }
}
