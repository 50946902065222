//
// Figure
// --------------------------------------------------

$figure-aspect-ratio: 10 / 16;

.c-figure {
  display: block;
}

// Image
.c-figure__img-wrapper {
  background-color: $color-gray-f;

  .c-figure--use-aspect-ratio & {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      padding-bottom: $figure-aspect-ratio * 100%;
    }
  }

  picture {
    top: 0; // mandatory for lazysizes' object-fit-poylfill to work correctly
    width: 100%;

    .c-figure--use-aspect-ratio & {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.c-figure__img {
  display: block;
  width: 100%;
  height: auto;
  transition: all $transition-time-medium;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
    height: rem(400px); // dummy height, still better than the square placeholder gif

    .c-figure--use-aspect-ratio & {
      height: 100%;
    }
  }

  .c-figure--use-aspect-ratio & {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

// Caption
.c-figure figcaption {
  margin-top: rem(24px);
}

// Centered modifier
.c-figure--centered {
  figcaption {
    text-align: center;
  }
}

// No-background modifier
.c-figure--no-bg {
  .c-figure__img-wrapper {
    background-color: transparent;
  }
}
