//
// Overlay
// --------------------------------------------------

.c-overlay {
  width: 100%;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: index($z-index, overlay);

  display: flex;
  flex-direction: column;

  color: $color-text;

  opacity: .75;
  transform: translateX(100%) translateX(1px); // workaround for a weird bug in chrome where the menu was visible
  transition: all .3s;
  // Out: Sharp
  transition-timing-function: cubic-bezier(.4, 0, .6, 1);

  &.is-open {
    // In: Deceleration
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
    opacity: 1;
    transform: none;
  }

  // background
  &::after {
    content: '';
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    @include mappy-bp(sm) {
      width: 55%;
      margin-left: auto;
      box-shadow: rem(-4px) 0 rem(6px) 0 rgba(0, 0, 0, .1);
    }
  }
}

.c-overlay__head,
.c-overlay__search,
.c-overlay__content {
  position: relative;
  z-index: 10; // > background
}

// Container inner
.c-overlay__inner {
  max-width: map-get(map-get($container-breakpoint-config, xs), max-width);
  margin-left: auto;
}

// Head (corresponds to topbar size)
.c-overlay__head {
  position: relative;
  z-index: 10; // > search & content

  width: 100%;
  height: rem($topbar-height);

  @include mappy-bp(sm) {
    height: rem($topbar-height-sm);
  }

  @include mappy-bp(lg) {
    height: rem($topbar-height-lg);
  }

  > .o-container {
    height: 100%;
  }
}

.c-overlay__head-inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;


  .c-lang-switch {
    margin-left: rem(-8px); // match lang switch padding
  }
}

// Closer
.c-overlay__closer {
  flex-shrink: 0;
  min-width: 0;
  display: block;
  color: $color-link;
}

.c-overlay__closer-icon {
  display: block;
  position: relative;
  width: rem($topbar-btn-size);
  height: rem($topbar-btn-size);

  @include mappy-bp(sm) {
    width: rem($topbar-btn-size-sm);
    height: rem($topbar-btn-size-sm);
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

// Search
.c-overlay__search {
  position: relative;
  z-index: 5; // < head, > content
  width: 100%;
}

// Content
.c-overlay__content {
  position: relative;
  z-index: 2; // < search, < head
  overflow: hidden;
  padding-bottom: rem(40px);

  @include mappy-bp(sm) {
    padding-bottom: rem(56px);
  }

  .c-overlay__search + & {
    margin-top: rem(20px);

    @include mappy-bp(sm) {
      margin-top: rem(30px);
    }
  }
}

.c-overlay__content-inner {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

// Make overlay component more global by using a menu modifier
.c-overlay--menu {
  @include mappy-bp(lg) {
    display: none;
  }
}
