.c-slideout-banner {
  background-color: #fff;
  padding: rem(16px) 0;

  @include mappy-bp(md) {
    padding: rem(64px) 0;
  }
}

.c-slideout-banner__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: rem(32px);

  @include mappy-bp(md) {
    flex-direction: row;
    gap: rem(40px);
  }

  @include mappy-bp(xl) {
    gap: rem(80px);
  }
}

// Graphic
.c-slideout-banner__graphic {
  flex-shrink: 0;
}

.c-slideout-banner__img-wrapper {
  width: rem(200px);

  @include mappy-bp(md) {
    width: rem(280px);
  }

  @include mappy-bp(xl) {
    width: rem(360px);
  }

  picture {
    width: 100%;
  }
}

.c-slideout-banner__img,
.c-slideout-banner__svg {
  display: block;
  width: 100%;
  height: auto;
  max-height: rem(150px);
  object-fit: contain;
  object-position: center bottom;
  transition: all $transition-time-default;

  @include mappy-bp(md) {
    max-height: rem(170px);
  }

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Content
.c-slideout-banner__content {
  max-width: map-get(map-get($container-breakpoint-config, md), max-width);

  .c-headline {
    color: #000;
  }

  @include mappy-bp(md) {
    padding-right: rem(48px);
  }

  .c-headline--multicolor {
    strong {
      font-weight: inherit;
      display: inline;
      color: $color-primary;
      background-color: transparent;
    }
  }
}

// Closer
.c-slideout-banner__closer {
  @include button-reset;
  cursor: pointer;
  color: $color-link;
  transition: color $transition-time-default;

  position: absolute;
  right: 0;
  top: 0;

  height: rem(24px);
  width: rem(24px);
  display: flex;
  align-items: center;
  justify-content: center;

  @include mappy-bp(md) {
    height: rem(32px);
    width: rem(32px);
  }

  &:hover,
  &:focus {
    color: $color-link-hover;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

