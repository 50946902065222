//
// Figure
// --------------------------------------------------
.c-breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
}

.c-breadcrumb__item {
  @include typi('breadcrumb');
  font-weight: 500;
  color: $color-link;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  align-items: center;

  a {
    color: inherit;

    display: flex;
    align-items: center;
    transition: all $transition-time-default;

    &:hover,
    &:focus {
      color: $color-link-hover;
    }

    svg {
      width: (18px / 14px) * 1em;
      height: (18px / 14px) * 1em;
      color: $color-link-hover;
      margin-right: rem(8px);
    }
  }

  &:not(:last-of-type)::after {
    content: '/';
    margin-left: rem(5px);
    margin-right: rem(5px);
  }
}

.c-breadcrumb__item--home {
  border-right: rem(1px) solid currentColor;
  margin-right: rem(16px);
  padding-right: rem(16px);

  &::after {
    display: none;
  }
}

.c-breadcrumb__item--current {
  font-weight: $font-bold;
  color: $color-link-hover;
}
