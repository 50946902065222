//
// Suppliers
// --------------------------------------------------

// Image
.c-supplier__img-wrapper {
  overflow: hidden;
  border: 1px solid $color-gray-b;

  picture {
    width: 100%;
  }
}

.c-supplier__img {
  display: block;
  width: 100%;
  height: auto;
  transition: all $transition-time-default;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}


// Grid
$suppliers-spacing: 32px;
$suppliers-spacing-s: 40px;
$suppliers-vertical-spacing: 32px;
$suppliers-vertical-spacing-s: 40px;

.c-suppliers__row {
  @include make-row-spacing($suppliers-spacing, $suppliers-vertical-spacing);

  @include mappy-bp(lg) {
    @include make-row-spacing($suppliers-spacing-s, $suppliers-vertical-spacing-s);
  }
}

.c-suppliers__col {
  @include make-col-spacing($suppliers-spacing, $suppliers-vertical-spacing);

  @include mappy-bp(lg) {
    @include make-col-spacing($suppliers-spacing-s, $suppliers-vertical-spacing-s);
  }
}

