//
// Downloads
// --------------------------------------------------

// Downloads list
.c-downloads {
  > li + li {
    margin-top: rem(1px);
  }
}

// Download
.c-download {
  background-color: $color-gray-f;
  padding: rem(16px);
  display: flex;

  @include mappy-bp(sm) {
    padding: rem(24px);
  }
}

.c-download__icon {
  width: rem(24px);
  height: rem(24px);
  flex-shrink: 0;
  margin-right: rem(8px);
  margin-top: .2em; // magic number for alignment

  @include mappy-bp(sm) {
    margin-right: rem(16px);
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.c-download__content {
  flex-grow: 1;
}

.c-download__text {
  @include typi('download');

  h6 {
    line-height: 1.5;
    color: #000;
  }

  p {
    font-size: inherit;
    line-height: inherit;
  }
}

.c-download__files {
  margin-top: rem(16px);

  // @include mappy-bp(max sm) {
  //   margin-left: rem(-24px - 8px);
  // }
}
