//
// Search
// --------------------------------------------------

.c-search__body {
  .c-search__head + & {
    margin-top: rem(40px);
  }
}

.c-search__result {
  & + & {
    margin-top: rem(24px);
  }
}
