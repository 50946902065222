//
// Text
// --------------------------------------------------

.c-text--s-intermediate {
  // nested container for intermediate viewports
  @include mappy-bp(max md) {
    max-width: map-get(map-get($container-breakpoint-config, s), max-width);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

// .c-text--narrow {
//   max-width: rem($content-width-narrow);
// }
