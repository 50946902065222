//
// Container object
// Adaptive version
// If you need special, non-linear left/right spacing, use padding or nested max-width on component-level!
// --------------------------------------------------

@mixin make-container() {
  // General container styles
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  // Container configs (max-width & padding)
  // Apply smallest container config for viewports below xs
  $smallestViewportConfig: map-get($container-breakpoint-config, xs);
  padding-left: map-get($smallestViewportConfig, padding);
  padding-right: map-get($smallestViewportConfig, padding);
  max-width: map-get($smallestViewportConfig, max-width) + (map-get($smallestViewportConfig, padding) * 2);

  // Loop through breakpoints and apply the container configs
  @each $breakpoint, $breakpoint-config in $container-breakpoint-config {
    @include mappy-bp($breakpoint) {
      padding-left: map-get($breakpoint-config, padding);
      padding-right: map-get($breakpoint-config, padding);
      max-width: map-get($breakpoint-config, max-width) + (map-get($breakpoint-config, padding) * 2);
    }
  }
}

// Generate modifier classes for limiting horizontal space
@mixin make-container-modifiers() {
  @each $breakpoint, $breakpoint-config in $container-breakpoint-config {
    $nextIndex: index(map-keys($container-breakpoint-config), $breakpoint) + 1;

    @if $nextIndex <= length($container-breakpoint-config) {
      $nextBreakpoint: nth(map-keys($container-breakpoint-config), $nextIndex);

      .o-container--max-#{$breakpoint} {
        @include mappy-bp($nextBreakpoint) {
          max-width: map-get($breakpoint-config, max-width) + (map-get($breakpoint-config, padding) * 2);
        }
      }
    }
  }
}

.o-container {
  @include make-container();
}

@include make-container-modifiers();
