//
// Header
// --------------------------------------------------

.c-header {
  position: relative;

  > .o-container {
    position: relative;
    z-index: 10; // > bg visual, > bg
  }

  &::after {
    content: '';
    background-color: $color-primary-lighter;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.c-header--with-cta {
  padding-bottom: rem(20px); // TODO: button height variable / 2

  &::after,
  .c-header__bg-visual {
    bottom: rem(20px); // TODO: button height variable / 2
  }
}

// BG visual
$header-visual-ratio: 646 / 1034;

.c-header__bg-visual {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5; // > bg
  overflow: hidden;
}

.c-header__bg-visual-inner {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;

  @include mappy-bp(xs) {
    max-width: 75%;
  }

  @include mappy-bp(md) {
    max-width: 55%;
  }

  &::after {
    content: '';
    display: block;
    padding-bottom: 100% * $header-visual-ratio;
  }

  svg {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    animation: headerVisual 9s infinite alternate ease-in-out;
  }
}

@keyframes headerVisual {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.c-header__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: rem(256px);
  padding-bottom: rem(32px);

  @include mappy-bp(sm) {
    min-height: rem(332px);
  }
}

.c-header__breadcrumb,
.c-header__content {
  width: 100%;
}

.c-header__breadcrumb {
  border-bottom: rem(1px) solid rgba($color-primary-dark, .2);
  padding-top: rem(16px);
  padding-bottom: rem(16px);

  @include mappy-bp(max sm) {
    display: none;
  }
}

.c-header__content {
  margin-top: auto;
  padding-top: rem(40px);
}

.c-header__cta {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
}
