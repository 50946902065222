//
// Media object
// --------------------------------------------------

.c-media-object {
  @include mappy-bp(sm) {
    display: flex;
    align-items: flex-start;
  }

  @include mappy-bp(lg) {
    align-items: stretch;
  }
}

.c-media-object--centered-s {
  @include mappy-bp(max sm) {
    text-align: center;
  }
}

.c-media-object--icon {
  background-color: $color-gray-f;
}

.c-media-object--reverse {
  flex-direction: row-reverse;
}

// Image
.c-media-object__img-wrapper {
  background-color: $color-gray-d;
  overflow: hidden;
  position: relative;
  z-index: 10; // > content box

  @include mappy-bp(sm) {
    flex-shrink: 0;
    width: (100% / 3);
  }

  @include mappy-bp(lg) {
    width: rem(405px);
  }

  picture {
    height: 100%;
  }

  .c-media-object--icon & {
    background-color: transparent;
    padding: rem(40px) rem(24px);
    padding-bottom: 0;

    @include mappy-bp(sm) {
      padding: rem(48px) rem(40px);
    }

    @include mappy-bp(lg) {
      padding: rem(72px);
      width: rem(405px - 72px);
    }

    picture {
      height: 100%;
      width: 100%;
    }
  }

  .c-media-object--icon:not(.c-media-object--reverse) & {
    @include mappy-bp(sm) {
      padding-right: 0;
    }

    @include mappy-bp(lg) {
      padding-right: 0;
    }
  }

  .c-media-object--icon.c-media-object--reverse & {
    @include mappy-bp(sm) {
      padding-left: 0;
    }

    @include mappy-bp(lg) {
      padding-left: 0;
    }
  }
}

.c-media-object__img {
  width: 100%;
  height: auto;
  max-height: rem(300px);
  display: block;
  object-fit: cover;

  transition: all $transition-time-medium;

  @include mappy-bp(sm) {
    max-height: none;
  }

  @include mappy-bp(lg) {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

.c-media-object__svg {
  width: 100%;
  height: 100%;
  display: block;
  flex-shrink: 0;

  @include mappy-bp(max sm) {
    max-height: rem(243px);
  }
}

.c-media-object__content-box {
  position: relative;
  padding: rem(40px) rem(24px);

  @include mappy-bp(sm) {
    flex-grow: 1;
    padding: rem(48px) rem(40px);
  }

  @include mappy-bp(lg) {
    padding: rem(72px);
  }

  .c-media-object--quote &,
  .c-media-object--contact-person & {
    @include mappy-bp(max sm) {
      padding-top: rem(24px);
      padding-bottom: rem(24px);
    }
  }

  &::before {
    content: '';
    background-color: $color-gray-f;
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    @include mappy-bp(sm) {
      left: -25%;
    }

    .c-media-object--icon & {
      display: none;
    }
  }
}

.c-media-object__content {
  position: relative;
  z-index: 10; // > background
}

.c-richtext .c-media-object__headline {
  @include mappy-bp(max sm) {
    padding-bottom: 0;
  }
}

.c-media-object__cta {
  @include mappy-bp(md) {
    margin-top: rem(24px);
  }
}
