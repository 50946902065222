//
// Alpha Card
// --------------------------------------------------

.c-card-alpha {
  position: relative;
  display: block;
  color: $color-link;
  transition: color $transition-time-default;
  overflow: hidden;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }

  .c-cards-alpha__col & {
    height: 100%;
  }
}

.c-card-alpha--square {
  // square aspect ratio
  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

// nopicto mode
.c-card-alpha--nopicto {
  background-color: $color-gray-f;
}

// visual
.c-card-alpha--visual {
  background-color: $color-gray-f;

  &::after {
    content: '';
    position: absolute;
    left: -25%;
    height: 150%;
    width: 150%;
    bottom: 45%;

    background-color: $color-tertiary-light;
    border-radius: 50%;
  }
}

.c-card-alpha__inner {
  position: relative;
  z-index: 10; // > bg
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include mappy-bp(sm) {
    padding: rem(16px);
  }

  .c-card-alpha--lg & {
    padding: rem(24px);
  }

  .c-card-alpha--nopicto & {
    padding: rem(8px);

    @include mappy-bp(sm) {
      padding: rem(20px) rem(24px);
    }
  }

  .c-card-alpha--square & {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

// Image
.c-card-alpha__img-wrapper {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;

  picture {
    height: 100%;
    width: 100%;
  }
}

.c-card-alpha__img,
.c-card-alpha__svg {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center bottom;
  transition: all $transition-time-default;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Content
.c-card-alpha__content {
  width: 100%;
  flex-shrink: 0;
  height: 21%;
  margin-top: rem(16px);

  display: flex;
  align-items: center;
  justify-content: center;

  .c-card-alpha--nopicto & {
    margin-top: 0;
    height: 100%;
  }

  .c-richtext {
    p {
      .c-card-alpha--lg & {
        @include typi('card-headline-lg');
      }

      .c-card-alpha--sm & {
        @include typi('card-headline-sm');
      }
    }

    strong {
      font-weight: $font-bold;
    }
  }
}


// Grid
$cards-alpha-spacing: 24px;
$cards-alpha-spacing-lg: 40px;
$cards-alpha-vertical-spacing: 24px;
$cards-alpha-vertical-spacing-lg: 40px;

.c-cards-alpha__row {
  @include make-row-spacing($cards-alpha-spacing, $cards-alpha-vertical-spacing);

  @include mappy-bp(lg) {
    @include make-row-spacing($cards-alpha-spacing-lg, $cards-alpha-vertical-spacing-lg);
  }
}

.c-cards-alpha__col {
  @include make-col-spacing($cards-alpha-spacing, $cards-alpha-vertical-spacing);

  @include mappy-bp(lg) {
    @include make-col-spacing($cards-alpha-spacing-lg, $cards-alpha-vertical-spacing-lg);
  }
}

