//
// Richtext table
// --------------------------------------------------

.c-table--responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @include mappy-bp(md) {
    overflow: visible;
  }

  table {
    width: 100%;
    min-width: rem(500px);
  }
}

.c-table__table {
  min-width: 100%;
}

table {
  // border-collapse: collapse;
  // border-style: hidden;
  caption-side: bottom;
  color: $color-text;
  @include typi('table');

  caption {
    margin-top: rem(16px);

    @include mappy-bp(sm) {
      margin-top: rem(24px);
    }
  }
}

// Rows
tr {
  // Head row
  thead & {
    background: transparent;
    border-bottom: rem(2px) solid currentColor;
  }

  // Body row
  tbody & {
    &:nth-of-type(odd) {
      background: $color-gray-f;
    }
  }

  // Foot row
  tfoot & {
    background: transparent;
    font-weight: 700;
  }

  // Row selection
  tbody &.is-selected {
    background: $color-primary-lighter;
  }
}

// Columns
th,
td {
  padding: rem(10px);

  > :first-child {
    padding-top: 0;
    margin-top: 0;
  }

  > :last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

th {
  border: 0 none;
  font-weight: 700;
  text-align: left;
}
