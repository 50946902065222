//
// Team member
// --------------------------------------------------

.c-team-member {
  position: relative;

  &::before {
    content: '';
    background-color: $color-gray-f;
    transition: opacity $transition-time-default;
    opacity: 0;

    position: absolute;
    left: rem(map-get(map-get($container-breakpoint-config, xs), padding) * -1);
    right: rem(map-get(map-get($container-breakpoint-config, xs), padding) * -1);
    bottom: rem(map-get(map-get($container-breakpoint-config, xs), padding) * -1);
    top: rem(map-get(map-get($container-breakpoint-config, xs), padding) * -1);

    @include mappy-bp(s) {
      left: rem(-20px);
      right: rem(-20px);
      bottom: rem(-20px);
      top: rem(-20px);
    }
  }

  &.is-open::before {
    opacity: 1;
  }
}

.c-team-member__inner {
  position: relative;
  z-index: 10; // > bg
}

// Image box
.c-team-member__img-box {
  position: relative;
  background-color: $color-gray-d;
  margin-bottom: rem(24px);

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

// Image
.c-team-member__alt-image {
  position: relative;
  z-index: 10; // > main image
  opacity: 0;
  transition: opacity $transition-time-default;

  .c-team-member.is-open & {
    opacity: 1;
  }
}

.c-team-member__img-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  overflow: hidden;

  picture {
    height: 100%;
  }
}

.c-team-member__img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: all $transition-time-medium;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

// Toggler
.c-team-member__toggler {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10; // > image

  .c-button__icon {
    transition: transform $transition-time-default;
  }

  &.is-open .c-button__icon {
    transform: rotate(-45deg);
  }
}


// Content
.c-team-member__head {
  transition: margin-bottom $transition-time-default;

  .c-team-member.is-open & {
    margin-bottom: rem(24px);
  }
}

.c-richtext .c-team-member__headline {
  @include typi('card-headline');
  padding-bottom: rem(8px);
  color: $color-primary;
}

.c-team-member__details {
  display: none;

  .c-team-member.is-open & {
    display: block;
  }
}

.c-team-member__socials {
  margin-bottom: rem(24px);
}

.c-team-member__phone {
  font-weight: 700;
}

.c-team-member__mail {
  font-weight: $font-bold;
  font-size: rem(16px);
}
