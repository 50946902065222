//
// Richtext spacing
// --------------------------------------------------

.c-richtext {
  // Inline elements
  p,
  address {
    padding-bottom: rem(24px);

    &.u-text-sm {
      padding-bottom: rem(16px);
    }
  }

  .c-intro {
    padding-bottom: rem(24px);

    @include mappy-bp(sm) {
      padding-bottom: rem(40px);
    }

    // For adjacent intro blocks, use default paragraph spacing
    + .c-intro {
      margin-top: rem(24px - 24px);

      @include mappy-bp(sm) {
        margin-top: rem(24px - 40px);
      }
    }
  }

  // Headlines
  .c-headline--h1 {
    padding-top: rem(24px);
    padding-bottom: rem(24px);

    @include mappy-bp(lg) {
      padding-bottom: rem(40px);
    }
  }

  .c-headline--h2 {
    padding-top: rem(24px);
    padding-bottom: rem(24px);

    @include mappy-bp(lg) {
      padding-bottom: rem(40px);
    }
  }

  .c-headline--h3 {
    padding-top: rem(24px);
    padding-bottom: rem(12px);

    @include mappy-bp(sm) {
      padding-bottom: rem(20px);
    }
  }

  .c-headline--h4 {
    padding-top: rem(24px);
    padding-bottom: rem(12px);

    @include mappy-bp(sm) {
      padding-bottom: rem(20px);
    }
  }

  .c-headline--h5 {
    padding-top: rem(16px);
    padding-bottom: rem(12px);

    @include mappy-bp(sm) {
      padding-top: rem(24px);
      padding-bottom: rem(20px);
    }
  }

  .c-headline--h6 {
    padding-top: rem(16px);
    padding-bottom: rem(12px);

    @include mappy-bp(sm) {
      padding-top: rem(24px);
      padding-bottom: rem(20px);
    }
  }

  .c-headline--meta {
    padding-top: rem(24px);
    padding-bottom: rem(16px);

    + .c-headline:not(.c-headline--ignore-meta) {
      padding-top: 0;
    }
  }


  hr {
    margin-top: rem(32px);
    margin-bottom: rem(32px);

    @include mappy-bp(lg) {
      margin-top: rem(48px);
      margin-bottom: rem(48px);
    }
  }


  // Lists
  ul,
  ol {
    padding-bottom: rem(24px); // paragraph

    li {
      &:not(:last-of-type) {
        margin-bottom: rem(16px);

        @include mappy-bp(sm) {
          margin-bottom: rem(24px);
        }
      }
    }

    // nested lists
    ul,
    ol {
      padding-top: rem(16px);
      padding-bottom: 0;

      @include mappy-bp(sm) {
        padding-top: rem(24px);
        padding-bottom: 0;
      }
    }
  }


  // Tables
  .c-table {
    margin-top: rem(32px);
    margin-bottom: rem(24px);

    @include mappy-bp(sm) {
      margin-top: rem(48px);
      margin-bottom: rem(40px);
    }

    @include mappy-bp(lg) {
      margin-top: rem(64px);
      margin-bottom: rem(64px);
    }

    ul li:not(:last-of-type),
    ol li:not(:last-of-type) {
      margin-bottom: rem(8px);
    }
  }


  // General
  &:not(.c-richtext--keep-top-spacing) > :first-child,
  .u-no-top-spacing {
    padding-top: 0;
    margin-top: 0;
  }

  &:not(.c-richtext--keep-bottom-spacing) > :last-child,
  .u-no-bottom-spacing {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
