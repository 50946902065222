//
// Gamma Card
// --------------------------------------------------

.c-card-gamma {
  position: relative;
  display: block;
  color: $color-link;
  transition: color $transition-time-default;
  overflow: hidden;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }

  .c-cards-alpha__col & {
    height: 100%;
  }
}

.c-card-gamma__inner {
  position: relative;
  z-index: 10; // > bg
  height: 100%;

  display: flex;
  flex-direction: column;
}

// Image
.c-card-gamma__img-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  background-color: $color-primary-lighter;

  // aspect ratio
  &::before {
    content: '';
    display: block;
    padding-bottom: (9 / 16) * 100%;
  }

  picture {
    width: 100%;
  }
}

.c-card-gamma__img,
.c-card-gamma__svg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  transition: all $transition-time-default;

  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
}

.c-card-gamma__svg {
  object-fit: contain;
  padding: rem(24px);
}

// Content
.c-card-gamma__content {
  width: 100%;
  flex-grow: 1;
  padding: rem(24px) rem(16px);
  background-color: #fff;
  border-top: rem(4px) solid $color-link;
  transition-duration: $transition-time-default;
  transition-property: border-color, background-color;

  .c-card-gamma:hover &,
  .c-card-gamma:focus & {
    border-color: $color-link-hover;
    background-color: $color-gray-f;
  }

  .c-richtext {
    .c-card-gamma__meta-headline {
      color: $color-text-dark;
      font-weight: 700;
    }

    .c-card-gamma__headline {
      @include typi('card-headline');

      &:not(:first-child) {
        margin-top: rem(8px);
      }
    }

    strong {
      font-weight: $font-bold;
    }
  }
}
