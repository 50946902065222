//
// Hero
// --------------------------------------------------

.c-hero {
  position: relative;

  @include mappy-bp(lg) {
    min-height: calc(100vh - (var(--topbar-height) * 1px));
    display: flex;
    align-items: center;
  }
}

.c-hero__scroll {
  display: none;

  @include mappy-bp(sm) {
    display: block;
    position: absolute;
    z-index: 20; // > content
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.c-hero__scroll-inner {
  position: relative;
}

.c-hero__scroll-inner2 {
  @include mappy-bp(sm) {
    position: absolute;
    right: 0;
    bottom: rem((map-get($button-height-size-md, sm) - $button-height-square) * .5);
  }

  @include mappy-bp(lg) {
    transform: translateX(-50%);
    right: auto;
    left: 50%;
    bottom: rem(20px);
  }

  .c-button {
    border: 0 none;
    border-radius: 50%;
    animation: bounce 10s infinite;
    animation-delay: 4s;

    &::before {
      border-radius: 50%;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
    }
  }
}

@keyframes bounce {
  0%,
  4%,
  10%,
  16%,
  20% {
    transform: translateY(0);
  }

  8% {
    transform: translateY(rem(-20px));
  }

  12% {
    transform: translateY(rem(-10px));
  }
}

.c-hero--eurodrives .c-hero__visual {
  @include mappy-bp(lg) {
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.c-hero--eurodrives .c-hero__visual-inner {
  width: calc(100% - #{rem(16px)});
  max-width: rem(640px);

  @include mappy-bp(lg) {
    width: 100%;
    height: 100%;
    max-width: none;
  }

  picture {
    width: 100%;

    @include mappy-bp(lg) {
      width: 100%;
      height: 100%;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: bottom right;
    transform: scale(1.2);
    transform-origin: right bottom;
    margin-top: -10%;
    max-height: 70vh;

    transition: opacity $transition-time-long;

    @include mappy-bp(s) {
      transform: scale(1);
      margin-top: -20%;
    }

    @include mappy-bp(lg) {
      margin-top: 0;
      height: 100%;
      width: 43%;
      max-height: none;
      transform: scale(1.65);
      transform-origin: 100% 22%;
      object-fit: cover;
      object-position: center right;
    }

    @include mappy-bp(xl) {
      width: 48%;
      transform: scale(1.85);
    }

    &.lazyload,
    &.lazyloading {
      opacity: 0;
    }
  }
}

.c-hero--comerdrives .c-hero__visual {
  @include mappy-bp(lg) {
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.c-hero--comerdrives .c-hero__visual-inner {
  width: calc(100% - #{rem(16px)});
  max-width: rem(640px);
  position: relative;

  @include mappy-bp(lg) {
    width: 100%;
    height: 100%;
    max-width: none;
  }

  picture {
    width: 100%;

    @include mappy-bp(lg) {
      width: 100%;
      height: 100%;
    }
  }

  .c-hero__visual-background {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top right;
    transform: scale(1.8);
    transform-origin: right top;
    max-height: 70vh;

    transition: opacity $transition-time-long;

    @include mappy-bp(lg) {
      margin-top: 0;
      height: 100%;
      width: 43%;
      max-height: none;
      transform: scale(1.15);
      transform-origin: top right;
      object-fit: cover;
      object-position: center right;
    }

    &.lazyload,
    &.lazyloading {
      opacity: 0;
    }
  }

  .c-hero__visual-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scale(.9);

    transition: opacity $transition-time-long;

    @include mappy-bp(lg) {
      height: 100%;
      width: 43%;
      max-height: none;
      transform: scale(.8);
      transform-origin: center right;
    }

    @include mappy-bp(xl) {
      transform: scale(.8);
      width: 40%;
    }

    &.lazyload,
    &.lazyloading {
      opacity: 0;
    }
  }
}

.c-hero__content {
  position: relative;
  z-index: 10;
  margin-top: rem(40px);

  @include mappy-bp(lg) {
    width: 100%;
    margin-top: 0;
    padding-top: rem(112px);
    padding-bottom: rem(112px);
  }
}

.c-hero__inner {
  @include mappy-bp(lg) {
    max-width: rem(740px);
    margin-left: auto;
  }
}

.c-richtext .c-hero__headline {
  @include typi('headline-alpha');
  text-transform: uppercase;
}

.c-hero__text,
.c-hero__buttons {
  @include mappy-bp(lg) {
    width: 70%;
    margin-left: auto;
  }
}
