//
// Richtext lists
// --------------------------------------------------

// get $typi.paragraph.null font size (without 'px')
$list-base-font-size: _ty-strip-unit(_ty-get-font-size(map-deep-get($typi, paragraph, null)));
$list-bullet-space: 24px;
$list-bullet-space-sm: 26px;
$list-bullet-space-lg: 26px;

$list-bullet-space-sub: 24px;
$list-bullet-space-sub-sm: 24px;
$list-bullet-space-sub-lg: 24px;

$list-bullet-space-sub-ol: 32px;
$list-bullet-space-sub-ol-sm: 36px;
$list-bullet-space-sub-ol-lg: 36px;

.c-richtext {
  // general list
  ul,
  ol {
    @include typi('paragraph');
    padding-left: rem($list-bullet-space);

    @include mappy-bp(sm) {
      padding-left: rem($list-bullet-space-sm);
    }

    @include mappy-bp(lg) {
      padding-left: rem($list-bullet-space-lg);
    }

    li {
      clear: left;

      // bullet point / counter / badge
      &::before {
        content: '';
        float: left;
        margin-left: rem(-$list-bullet-space);

        @include mappy-bp(sm) {
          margin-left: rem(-$list-bullet-space-sm);
        }

        @include mappy-bp(lg) {
          margin-left: rem(-$list-bullet-space-lg);
        }
      }

      p:last-of-type,
      p.p-last {
        padding: 0;
      }

      // sub list spacing
      ul,
      ol {
        padding-left: rem($list-bullet-space-sub);

        @include mappy-bp(sm) {
          padding-left: rem($list-bullet-space-sub-sm);
        }

        @include mappy-bp(lg) {
          padding-left: rem($list-bullet-space-sub-lg);
        }

        li {
          // bullet point / counter / badge
          &::before {
            margin-left: rem(-$list-bullet-space-sub);

            @include mappy-bp(sm) {
              margin-left: rem(-$list-bullet-space-sub-sm);
            }

            @include mappy-bp(lg) {
              margin-left: rem(-$list-bullet-space-sub-lg);
            }
          }
        }
      }
    }
  }


  // bullet point
  ul:not(.c-list--checked) li::before {
    background-color: $color-secondary-light;
    border-radius: 50%;
    width: rem(8px);
    height: rem(8px);
    margin-top: .49em; // magic number, based on the bullet point dimensions and font-size

    @include mappy-bp(sm) {
      width: rem(10px);
      height: rem(10px);
    }
  }

  ul:not(.c-list--checked) li ul li::before {
    background-color: $color-secondary-lighter;

    @include mappy-bp(sm) {
      margin-top: .55em;
      width: rem(8px);
      height: rem(8px);
    }
  }


  // counter
  ol {
    counter-reset: item;

    li {
      &::before {
        content: counter(item) ".";
        counter-increment: item;

        font-weight: 700;
        color: inherit;
      }
    }
  }

  // sub ol
  ol li ol {
    padding-left: rem($list-bullet-space-sub-ol);

    @include mappy-bp(sm) {
      padding-left: rem($list-bullet-space-sub-ol-sm);
    }

    @include mappy-bp(lg) {
      padding-left: rem($list-bullet-space-sub-ol-lg);
    }

    // counter
    li::before {
      content: counters(item, ".");
      margin-left: rem(-$list-bullet-space-sub-ol);

      @include mappy-bp(sm) {
        margin-left: rem(-$list-bullet-space-sub-ol-sm);
      }

      @include mappy-bp(lg) {
        margin-left: rem(-$list-bullet-space-sub-ol-lg);
      }
    }
  }

  // primary bullet point / counter
  ul.c-list--primary li::before,
  ul.c-list--primary li ul li::before {
    background-color: $color-primary;
  }

  ol.c-list--primary li::before,
  ol.c-list--primary li ol li::before {
    color: $color-primary;
  }

  // checked list
  ul.c-list--checked {
    padding-left: rem($list-bullet-space + 4px);

    @include mappy-bp(sm) {
      padding-left: rem($list-bullet-space-sm + 8px);
    }

    @include mappy-bp(lg) {
      padding-left: rem($list-bullet-space-lg + 8px);
    }

    li {
      font-weight: 700;

      &::before {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.8 3.134a.439.439 0 0 0-.62 0L9.522 13.792 3.82 8.309a.439.439 0 0 0-.62 0l-3.072 3.07a.439.439 0 0 0 0 .621l9.084 8.865c.171.172.45.172.62 0l14.04-14.04a.439.439 0 0 0 0-.62L20.8 3.135z' fill='#{url-friendly-color($color-primary)}' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
        width: rem(16px);
        height: rem(16px);
        margin-left: rem(-$list-bullet-space - 4px);
        margin-top: .1em;

        @include mappy-bp(sm) {
          width: rem(20px);
          height: rem(20px);
          margin-left: rem(-$list-bullet-space-sm - 8px);
        }

        @include mappy-bp(lg) {
          margin-left: rem(-$list-bullet-space-lg - 8px);
        }
      }
    }
  }
}

// Inverted richtext
.c-richtext--inverted {
  ul:not(.c-list--checked) li::before,
  ul:not(.c-list--checked) li ul li::before {
    background-color: currentColor;
  }
}

// Richtext on green bg
.c-richtext--on-green {
  ul:not(.c-list--checked) li::before,
  ul:not(.c-list--checked) li ul li::before {
    background-color: $color-primary;
  }
}
