//
// Buttons
// --------------------------------------------------

$button-height-size-xs: (
  null: 32px,
);
$button-height-size-sm: (
  null: 40px,
);
$button-height-size-md: (
  null: 50px,
  sm: 56px
);
$button-height-size-lg: (
  null: 56px,
  sm: 72px
);
$button-height-square: 44px;

$button-border-width: 2px;
$button-border-width-lg: 3px;

// List of parent classes, which the button hover should react to
$buttonHoverClasses: ".c-card-alpha:hover &, .c-card-alpha:focus &";

.c-button {
  @include button-reset;
  cursor: pointer;
  font-weight: $font-bold;
  text-transform: uppercase;
  transition-property: all;
  transition-duration: $transition-time-default;

  position: relative; // for background
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  min-width: rem(130px);

  border: solid;

  // Hover background
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: rem(-$button-border-width);
    right: rem(-$button-border-width);
    bottom: rem(-$button-border-width);
    top: rem(-$button-border-width);

    transition-property: border-color, background-color, opacity;
    transition-duration: $transition-time-default;
    opacity: 0;
  }

  #{$buttonHoverClasses},
  &:hover,
  &:focus {
    &::before {
      opacity: 1;
    }
  }
}

// Icon
.c-button__icon {
  z-index: 10; // > background
  flex-shrink: 0;
}

// Text
.c-button__text {
  z-index: 10; // > background

  .c-button--icon-only & {
    display: none;
  }
}


// Sizes
@mixin renderButtonHeights($map) {
  @each $breakpoint, $height in $map {
    @if $breakpoint == null {
      min-height: rem($height);
    }
    @else {
      @include mappy-bp($breakpoint) {
        min-height: rem($height);
      }
    }
  }
}

// XS
.c-button--size-xs {
  // Button
  @include typi('button-sm');
  @include renderButtonHeights($button-height-size-xs);
  min-width: 0;
  border-width: rem($button-border-width);
  padding-left: rem(16px - $button-border-width);
  padding-right: rem(16px - $button-border-width);

  // Icon
  .c-button__icon {
    width: rem(16px);
    height: rem(16px);
  }

  // Inner spacing
  .c-button__text + .c-button__icon,
  .c-button__icon + .c-button__text {
    margin-left: rem(8px);
  }
}

// SM
.c-button--size-sm {
  // Button
  @include typi('button-sm');
  @include renderButtonHeights($button-height-size-sm);
  border-width: rem($button-border-width);
  padding-left: rem(16px - $button-border-width);
  padding-right: rem(16px - $button-border-width);

  // Icon
  .c-button__icon {
    width: rem(16px);
    height: rem(16px);
  }

  // Inner spacing
  .c-button__text + .c-button__icon,
  .c-button__icon + .c-button__text {
    margin-left: rem(8px);
  }
}

// MD
.c-button--size-md {
  // Button
  @include typi('button-md');
  @include renderButtonHeights($button-height-size-md);
  border-width: rem($button-border-width);
  padding-left: rem(16px - $button-border-width);
  padding-right: rem(16px - $button-border-width);

  @include mappy-bp(sm) {
    padding-left: rem(24px - $button-border-width);
    padding-right: rem(24px - $button-border-width);
  }

  // Icon
  .c-button__icon {
    width: rem(18px);
    height: rem(18px);

    @include mappy-bp(sm) {
      width: rem(24px);
      height: rem(24px);
    }
  }

  // Inner spacing
  .c-button__text + .c-button__icon,
  .c-button__icon + .c-button__text {
    margin-left: rem(16px);
  }
}

// LG
.c-button--size-lg {
  // Button
  @include typi('button-lg');
  @include renderButtonHeights($button-height-size-lg);
  border-width: rem($button-border-width-lg);
  padding-left: rem(24px - $button-border-width-lg);
  padding-right: rem(24px - $button-border-width-lg);

  // Icon
  .c-button__icon {
    width: rem(24px);
    height: rem(24px);

    @include mappy-bp(sm) {
      width: rem(40px);
      height: rem(40px);
    }
  }

  // Inner spacing
  .c-button__text + .c-button__icon,
  .c-button__icon + .c-button__text {
    margin-left: rem(16px);
  }
}

// Square
.c-button--size-square {
  min-width: 0;
  height: rem($button-height-square);
  width: rem($button-height-square);
  border-width: rem($button-border-width);

  .c-button__icon {
    width: rem(24px);
    height: rem(24px);
  }
}


// Types

// Primary
.c-button--type-primary {
  color: $color-primary;
  background-color: #000;
  border-color: #000;

  &::before {
    background-color: $color-primary;
  }

  #{$buttonHoverClasses},
  &:hover,
  &:focus {
    color: #fff;
  }
}

// Primary ghost
.c-button--type-primary-ghost {
  color: $color-primary;
  background-color: transparent;
  border-color: currentColor;

  &::before {
    background-color: transparent;
  }

  #{$buttonHoverClasses},
  &:hover,
  &:focus {
    color: #000;
  }

  // TODO: Move into modifier (--icon-inverted?)
  // .c-button__icon {
  //   color: #000;
  // }
}

// Secondary
.c-button--type-secondary {
  color: #000;
  background-color: $color-primary;
  border-color: $color-primary;

  &::before {
    background-color: #fff;
  }
}

// Secondary ghost
.c-button--type-secondary-ghost {
  color: $color-primary;
  background-color: transparent;
  border-color: currentColor;

  &::before {
    background-color: transparent;
  }

  #{$buttonHoverClasses},
  &:hover,
  &:focus {
    color: #fff;
  }
}

// Tertiary
.c-button--type-tertiary {
  color: #fff;
  background-color: $color-primary;
  border-color: $color-primary;

  &::before {
    background-color: #fff;
  }

  #{$buttonHoverClasses},
  &:hover,
  &:focus {
    color: #000;
  }
}

// Block
.c-button--block {
  display: flex;
  min-width: 0;
}

.c-button--block-xs {
  @include mappy-bp(max sm) {
    display: flex;
    min-width: 0;
  }
}


// Readonly
.c-button--readonly {
  pointer-events: none;
}


// Button group
// Horizontal spacing
$button-group-spacing-x: 24px / 2;
$button-group-spacing-x-sm: 32px / 2;
$button-group-spacing-x-narrow: 16px / 2;

// Vertical spacing
$button-group-spacing-y: 24px;
$button-group-spacing-y-sm: 32px;
$button-group-spacing-y-narrow: 16px;

.c-button-group {
  overflow: hidden;

  .c-button {
    margin-top: rem($button-group-spacing-y);
    margin-left: rem($button-group-spacing-x);
    margin-right: rem($button-group-spacing-x);

    @include mappy-bp(sm) {
      margin-top: rem($button-group-spacing-y-sm);
      margin-left: rem($button-group-spacing-x-sm);
      margin-right: rem($button-group-spacing-x-sm);
    }
  }
}

.c-button-group--stretch {
  .c-button {
    flex-grow: 1;
  }
}

.c-button-group--narrow {
  .c-button {
    margin-top: rem($button-group-spacing-y-narrow);
    margin-left: rem($button-group-spacing-x-narrow);
    margin-right: rem($button-group-spacing-x-narrow);
  }
}

.c-button-group__inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: rem(-$button-group-spacing-y);
  margin-left: rem(-$button-group-spacing-x);
  margin-right: rem(-$button-group-spacing-x);

  @include mappy-bp(sm) {
    margin-top: rem(-$button-group-spacing-y-sm);
    margin-left: rem(-$button-group-spacing-x-sm);
    margin-right: rem(-$button-group-spacing-x-sm);
  }

  .c-button-group--narrow & {
    margin-top: rem(-$button-group-spacing-y-narrow);
    margin-left: rem(-$button-group-spacing-x-narrow);
    margin-right: rem(-$button-group-spacing-x-narrow);
  }

  .c-button-group--centered & {
    justify-content: center;
    align-items: center;
  }

  .c-button-group--column-xs & {
    @include mappy-bp(max sm) {
      flex-direction: column;
    }
  }
}
