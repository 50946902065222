//
// Form elements
// --------------------------------------------------

// Selects library
@import 'slimselect';

.c-form--narrow {
  max-width: map-get(map-get($container-breakpoint-config, s), max-width);
}

// General formbuilder styles
.c-form__hint {
  display: flex;
  align-items: center;

  svg {
    margin-right: rem(8px);
    width: rem(8px);
    height: rem(8px);
    color: #000;
  }
}

.c-form__required {
  font-size: 1.5em;
  line-height: 0;
  vertical-align: middle;
}


// Errors
.c-form__form-errors {
  margin-bottom: rem(32px);
}

.c-form__field-errors {
  display: none;
  color: $color-error;
  @include typi('paragraph-sm');
  margin-top: rem(8px);

  .has-error + & {
    display: block;
  }
}

.c-form__errorlist {
  li + li {
    margin-top: rem(3px);
  }
}


// Poneyhot
[class*=wf-strawberry_mug] {
  display: none;
}

// reCAPTCHA
.c-form__recaptcha-message {
  margin-top: rem(24px);
}

.grecaptcha-badge { visibility: hidden; }

// Form elements
.c-input,
.c-checkbox,
.c-radio,
.c-textarea,
.c-filefield {
  position: relative;

  &.is-disabled,
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
    pointer-events: none;
  }
}

// Form labels
.c-form__label {
  display: inline-block;
  @include typi('form-label');
  font-weight: $font-bold;
  font-style: normal;
  cursor: pointer;
  margin-bottom: rem(14px);
  color: #000;

  .has-error & {
    color: $color-error;
  }
}

.c-input__label,
.c-textarea__label,
.c-select__label,
.c-filefield__label {
  @extend .c-form__label;
}

// Textarea
.c-textarea__field {
  @include typi('form-element');
  font-weight: 400;
  color: $form-input-color;
  background-color: #fff;
  width: 100%;
  min-height: rem(110px);
  resize: vertical;
  border: rem(1px) solid $form-border-color;
  padding: rem($form-padding-y) rem($form-padding-x);
  white-space: pre-wrap;

  @include mappy-bp(sm) {
    min-height: rem(175px);
  }

  &:focus {
    outline: none;
    border-color: $form-border-color-focus;
  }

  &::placeholder {
    color: currentColor;
    font-weight: 400;
    font-style: normal;
    opacity: 1;
  }

  .c-textarea.has-error & {
    border-color: $color-error;
  }
}

// Inputs
$input-icon-size: 20px;
$input-icon-spacing: 12px;
$input-cancel-size: 16px;

.c-input__field {
  @include typi('form-element');
  font-weight: 400;
  color: $form-input-color;
  background-color: #fff;

  width: 100%;
  min-height: rem($form-input-height);
  padding: rem($form-padding-y) rem($form-padding-x);
  border: rem(1px) solid $form-border-color;

  &:focus {
    outline: none;
    border-color: $form-border-color-focus;
  }

  &::placeholder {
    color: currentColor;
    font-weight: 400;
    font-style: normal;
    opacity: 1;
  }

  .c-input.has-error & {
    border-color: $color-error;
  }

  .c-input--has-icon & {
    padding-right: rem($form-padding-x + $input-icon-size + $input-icon-spacing - 1px);
  }

  .c-input--cancelable & {
    padding-right: rem($form-padding-x + $input-icon-size + $input-icon-spacing - 1px + ($input-cancel-size + $input-icon-spacing));
  }

  &[type="search"] {
    // hide native cancel button since we're implementing our own
    &::-webkit-search-cancel-button {
      display: none;
    }
  }
}

// input icon
.c-input__icon {
  @include button-reset;
  color: $color-link;
  transition: all $transition-time-default;

  position: absolute;
  top: 50%;
  right: rem(16px);
  transform: translateY(-50%);
  height: rem($input-icon-size);
  width: rem($input-icon-size);

  svg {
    height: 100%;
    width: 100%;
  }
}

// interactive icon
.c-input__icon--interactive {
  cursor: pointer;

  &:hover,
  &:focus {
    color: $color-link-hover;
  }
}

// cancel button
.c-input__cancel {
  @include button-reset;
  display: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: rem($form-padding-x + $input-icon-size + $input-icon-spacing - 1px);
  transform: translateY(-50%);

  height: rem($input-cancel-size);
  width: rem($input-cancel-size);
  border-radius: 50%;
  background-color: $color-link;
  color: #fff;
  fill: #fff;
  opacity: 0;
  transition: all $transition-time-default;

  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: $color-link-hover;
    opacity: 1;
  }

  .c-input--cancelable.is-filled & {
    display: flex;
  }

  .c-input__field:hover ~ &,
  .c-input__field:focus ~ & {
    opacity: 1;
  }

  svg {
    height: 50%;
    width: 50%;
  }
}


// Checkboxes & radios
.c-radio,
.c-checkbox {
  & + & {
    margin-top: rem(8px);
  }
}

.c-checkbox__input,
.c-radio__input {
  @extend .u-invisible;

  // better positioning for custom validation
  margin: 0;
  top: rem($form-checkbox-radio-size);
  left: rem($form-checkbox-radio-size / 2);
}

.c-checkbox__label,
.c-radio__label {
  @include typi('form-label');
  line-height: _ty-get-line-height(map-deep-get($typi, paragraph, null));

  display: flex;
  align-items: center;
  cursor: pointer;

  // ugly copy of .c-richtext a
  a {
    color: $color-link;
    transition: color $transition-time-default;

    &:hover,
    &:focus {
      color: $color-link-hover;
      text-decoration: underline;
    }
  }

  // frame
  &::before {
    align-self: baseline;
    flex-shrink: 0;
    margin-right: rem(16px);

    content: '';
    display: block;
    cursor: pointer;
    background: transparent;
    border: rem(1px) solid $color-primary;
    width: rem($form-checkbox-radio-size);
    height: rem($form-checkbox-radio-size);
    text-align: center;
    transition: all $transition-time-default ease-out;

    .c-checkbox.has-error &,
    .c-radio.has-error & {
      border-color: $color-error;
    }
  }

  // indicator
  &::after {
    content: '';
    display: block;
    color: $color-primary-darker;
    opacity: 0;
    cursor: pointer;

    position: absolute;
    left: rem($form-checkbox-radio-size / 2);
    top: rem($form-checkbox-radio-size / 2);
    transform: translate(-50%, -50%);
    background-size: contain;
    background-repeat: no-repeat;

    .c-radio__input:checked + &,
    .c-checkbox__input:checked + & {
      opacity: 1;
    }
  }
}

// radio frame
.c-radio__label::before {
  border-radius: 50%;
}

// radio indicator
.c-radio__label::after {
  width: rem($form-checkbox-radio-size * 10 / 22);
  height: rem($form-checkbox-radio-size * 10 / 22);
  // TODO: correct icon
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Symbols' fill='#{url-friendly-color($color-primary-darker)}' fill-rule='evenodd'%3E%3Cg id='assets/images/svg-raw/ic-radiobutton' transform='translate(-4 -4)' fill='#{url-friendly-color($color-primary-darker)}'%3E%3Ccircle id='Oval' cx='12' cy='12' r='8'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

  .c-checkbox.has-error &,
  .c-radio.has-error & {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Symbols' fill='#{url-friendly-color($color-error)}' fill-rule='evenodd'%3E%3Cg id='assets/images/svg-raw/ic-radiobutton' transform='translate(-4 -4)' fill='#{url-friendly-color($color-error)}'%3E%3Ccircle id='Oval' cx='12' cy='12' r='8'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
}

// checkbox indicator
.c-checkbox__label::after {
  width: rem($form-checkbox-radio-size * 13 / 22);
  height: rem($form-checkbox-radio-size * 13 / 22);
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.8 3.134a.439.439 0 0 0-.62 0L9.522 13.792 3.82 8.309a.439.439 0 0 0-.62 0l-3.072 3.07a.439.439 0 0 0 0 .621l9.084 8.865c.171.172.45.172.62 0l14.04-14.04a.439.439 0 0 0 0-.62L20.8 3.135z' fill='#{url-friendly-color($color-primary-darker)}' fill-rule='nonzero'/%3E%3C/svg%3E%0A");

  .c-checkbox.has-error &,
  .c-radio.has-error & {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.8 3.134a.439.439 0 0 0-.62 0L9.522 13.792 3.82 8.309a.439.439 0 0 0-.62 0l-3.072 3.07a.439.439 0 0 0 0 .621l9.084 8.865c.171.172.45.172.62 0l14.04-14.04a.439.439 0 0 0 0-.62L20.8 3.135z' fill='#{url-friendly-color($color-error)}' fill-rule='nonzero'/%3E%3C/svg%3E%0A");
  }
}


// File field
.c-filefield__field {
  display: block;
}

.filepond--root {
  font-family: inherit;
  margin-bottom: 0;
}

.filepond--panel-root {
  background-color: $color-gray-e;
  border-radius: 0;
}

.filepond--item-panel {
  border-radius: 0;
  background-color: $color-tertiary;
}

/* error state color */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: $color-error;
}

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: $color-primary;
}
