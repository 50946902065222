//
// Mixins
// --------------------------------------------------

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin no-font-smoothing {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

@mixin font-sans {
  font-family: $font-family-sans-fallback;
  font-weight: 400;

  // fonts-loaded global flag, triggered by JS
  .has-loaded-fonts-sans & {
    font-family: $font-family-sans;
  }
}

@mixin font-ci {
  font-family: $font-family-ci-fallback;
  font-weight: 400;

  // fonts-loaded global flag, triggered by JS
  .has-loaded-fonts-ci & {
    font-family: $font-family-ci;
  }
}

// Reset all default button styles
@mixin button-reset {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
  white-space: normal;

  &:focus {
    outline: none;
  }
}

// blurred when overlay is active
@mixin overlay-blur($withOpacity: true) {
  @include mappy-bp(max lg) {
    transition: filter .3s, opacity .3s;

    .is-overlay-menu-open & {
      filter: blur(rem(4px));

      @if $withOpacity {
        opacity: .2;
      }
    }
  }
}
