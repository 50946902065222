// Selection page
.c-product-offers__submit {
  text-align: right;
}

.c-product-offers__table {
  margin-top: rem(24px);
  margin-bottom: rem(24px);
  white-space: nowrap;


  @include mappy-bp(sm) {
    margin-top: rem(48px);
    margin-bottom: rem(48px);
  }

  tbody tr {
    cursor: pointer;
  }

  td {
    vertical-align: middle;
  }

  .c-checkbox__label {
    &::before {
      margin-right: 0;
    }
  }
}

// Form page
.c-product-offers-request__table {
  white-space: nowrap;
}
