//
// Language switcher
// --------------------------------------------------

.c-lang-switch {
  position: relative;
  color: $color-link-hover;
  @include typi('service');
  font-weight: 500;

  @include mappy-bp(lg) {
    &:hover,
    &:focus {
      background-color: $color-gray-f;
    }
  }

  &.is-open {
    background-color: $color-gray-f;
  }
}

.c-lang-switch__current {
  padding: rem($lang-switch-padding);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.c-lang-switch__flag {
  width: rem(16px);
  height: rem(16px);
  margin-right: rem(8px);
}

.c-lang-switch__indicator {
  margin-left: rem(8px);
  color: $color-primary-dark;
  width: rem(12px);
  height: rem(12px);
  transition: transform $transition-time-default;
  will-change: transform;

  .c-lang-switch.is-open & {
    transform: rotate(180deg);
  }
}

.c-lang-switch__list {
  position: absolute;
  top: 100%;
  transform: translateY(rem(6px - $lang-switch-padding));
  left: 0;
  right: 0;
  background-color: $color-gray-f;
  padding-bottom: rem(6px);

  display: none;

  .c-lang-switch.is-open & {
    display: block;
  }

  a {
    color: inherit;
    display: flex;
    align-items: center;
    padding: rem(6px) rem($lang-switch-padding);

    &:hover,
    &:focus {
      color: inherit;
      background-color: $color-gray-d;
    }
  }
}
